import { request } from "@/utils";
import axios from 'axios';
import { getUserDeviceId } from "@/utils/device.js";
import { useGenerateStore } from "@/store/modules/generate";
import {getEnvConfig} from '@/utils/functions';

function getApiHost(){
  const generateStore = useGenerateStore();
  let str = '/api';
  if(generateStore.generate.tenant_key){
    str = '/iframe';
  }
  return str;
}

//import { EventSourcePolyfill } from 'event-source-polyfill';

/**
 * 获取微信二维码和票据号
 * @returns 
 */
 export const login = () => {
  return request.get(`${getApiHost()}/wechat/qrcode`);
}


/**
 * 需要轮询，检测扫码关注是否成功
 * @param title 
 * @returns taskID
 */
 export const wechatCheck = (sign: string, from: string,bd_vid:string,qhclickid:string) => {
  return request.get(`${getApiHost()}/wechat/check?sign=${sign}&from=${from}&bd_vid=${bd_vid}&qhclickid=${qhclickid}&device_id=${getUserDeviceId()}`);
}

/**
 * 需要轮询，检测扫码关注是否成功
 * @param title 
 * @returns taskID
 */
 export const getUserInfo = () => {
  return request.get(`${getApiHost()}/user/info`);
}

/**
 * 用ai增加一页
 * @param data //  title:  输入的内容 type：类型，生成一页固定传 1; task_id：任务ID，任务接口返回的task_id  
 * @returns content
 */
export const syncGetChatContent = (data:any) => {
  return request.post(`${getApiHost()}/sync/chat/content`, data)
}

/**
 * addTask
 * @param title 
 * @returns taskID
 */
 export const addTask = (title:string,type:number=1,sub_type?:any) => {
    const generateStore = useGenerateStore();
    let params = {
      title,
      device_id: getUserDeviceId(),
      type:type,
      source:1,
      sub_type:sub_type?Number(sub_type):0
    };
    if(generateStore.generate.userInfo?.id){
      params.user_id = generateStore.generate.userInfo?.id;
    }
    // const suit_ids = exclude.join(',')
    // &colour_id=${color}&page=${page}&page_size=${page_size}&suit_ids=${suit_ids}
    return request.post(`${getApiHost()}/chat/task`, params)
  }

  export const inspectUsable = (title:string,type:number=1,sub_type?:any) => {
    const generateStore = useGenerateStore();
    let params = {
      title,
      device_id: getUserDeviceId(),
      type:type,
      source:1,
      sub_type:sub_type?Number(sub_type):0
    };
    if(generateStore.generate.userInfo?.id){
      params.user_id = generateStore.generate.userInfo?.id;
    }
    // const suit_ids = exclude.join(',')
    // &colour_id=${color}&page=${page}&page_size=${page_size}&suit_ids=${suit_ids}
    return request.post(`${getApiHost()}/chat/usable`, params)
  }

  /**
 * 生成内容
 * @param title 
 * @returns taskID
 */
 export const generateContent = (title:string, taskId:any, onMessage: (res: string) => void, onComplate: () => void, onError?: (res: any) => void, onContentFilter?: () => void) => {
    // return request.get(`${getApiHost()}/chat/content?title=${title}&task_id=${taskId}`)

  const eventSource = new EventSource(`${getApiHost()}/chat/content/v4?task_id=${taskId}`)
  eventSource.addEventListener("message", function (event: any) {
    try {
      const data = JSON.parse(event.data);
      // if (data.finish_reason === 'stop') {
      //   eventSource.close()
      //   onComplate()
      //   return
      // }
      // if (data.finish_reason && data.finish_reason != 'stop' && data.finish_reason != "") {
      //   eventSource.close()
      //   onContentFilter && onContentFilter()
      //   return
      // }
      if (data.content) {
        onMessage(data.content)
      }
    } catch (error) {
      onError && onError(event)
    }

  });
  eventSource.addEventListener("error", function (event: any) {
    eventSource.close()
    onError && onError(event)
  })
  eventSource.addEventListener("close", function (event: any) {
    eventSource.close()
    onComplate && onComplate(event)
  })
}

export const openGenerateContent = (taskId:any, onMessage: (res: string) => void, onComplate: () => void, onError?: (res: any) => void) => {
const eventSource = new EventSource(`/open/chat/v3?task_id=${taskId}`)
eventSource.addEventListener("message", function (event: any) {
  try {
    const data = JSON.parse(event.data);
    if (data.content) {
      onMessage(data.content)
    }
  } catch (error) {
    onError && onError(event)
  }

});
eventSource.addEventListener("error", function (event: any) {
  eventSource.close()
  onError && onError(event)
})
eventSource.addEventListener("close", function (event: any) {
  eventSource.close()
  onComplate && onComplate(event)
})
}

//
export const postOpenPPTFile = (params: any) => {
  return request.post(`/open/ppt/origin/file`, params);
}
  /**
 * 生成内容
 * @param title 
 * @returns taskID
 */
  export const speechGetSEEData = (title:string, taskId:any,type:number, onMessage: (res: string) => void, onComplate: () => void, onError?: (res: any) => void, onContentFilter?: () => void) => {
    // return request.get(`${getApiHost()}/chat/content?title=${title}&task_id=${taskId}`)

  const eventSource = new EventSource(`${getApiHost()}/speech/chat/content/?task_id=${taskId}&title=${title}&page_type=${type}`)
  eventSource.addEventListener("message", function (event: any) {
    try {
      const data = JSON.parse(event.data);
      // if (data.finish_reason === 'stop') {
      //   eventSource.close()
      //   onComplate()
      //   return
      // }
      // if (data.finish_reason && data.finish_reason != 'stop' && data.finish_reason != "") {
      //   eventSource.close()
      //   onContentFilter && onContentFilter()
      //   return
      // }
      if (data.content) {
        onMessage(data.content)
      }
    } catch (error) {
      onError && onError(event)
    }

  });
  eventSource.addEventListener("error", function (event: any) {
    eventSource.close()
    onComplate && onComplate(event)
  })
  eventSource.addEventListener("close", function (event: any) {
    eventSource.close()
    onComplate && onComplate(event)
  })
  return eventSource
}


/**
 * 埋点
 * @returns 
 */
 export const actionStatistic = (params: any) => {
  return request.post(`${getApiHost()}/action/statistic`, params);
}


/**
 * viplist
 * @returns 
 */
export const getVipList = (params?: any) => {
  return request.get(`${getApiHost()}/vip/list`,params);
}

/**
 * 埋点
 * @returns 
 */
export const orderCreate = (params: any) => {
  return request.post(`${getApiHost()}/order/create`, params);
}


/**
 * 获取地址，以生成二维码
 * @returns 
 */
export const payUrl = (no:number) => {
  return request.get(`${getApiHost()}/pay/url?out_trade_no=${no}`);
}

/**
 * 轮询支付
 * @returns 
 */
export const payOrderInfo = (no:number) => {
  return request.get(`${getApiHost()}/pay/order/info?out_trade_no=${no}`);
}
/**
 * 上传OSS
 * 
 */
export const uploadOss = async (
  file: File,
  { format, upload_type }: any,
  ossResConfig?: any,
) => {
  const access_token = localStorage.getItem('token')
  const generateStore = useGenerateStore();
  if (format === 'image') {
    format = 'png';
  }
  let str = `format=${format}&upload_type=${upload_type}`;
  let headers:any = {
    ['authorization']: `Bearer ${access_token}`,
  }
  if(generateStore.generate.tenant_key){
    headers = {};
    headers!['Q-Token'] = generateStore.generate.tenant_obj?.token;
    headers!['Q-Uuid'] = generateStore.generate.tenant_obj?.uuid;
    headers!['Q-Apikey'] = generateStore.generate.tenant_obj?.apikey;
  }
  // 获取token
  const ossToken = await fetch(`${getApiHost()}/oss/upload/token?` + str, {
    method: 'GET',
    headers: headers,
  })
    .then(data => data.json())
    .then(data => data.data);
  const ossFormData = new FormData();
  ossFormData.append('success_action_status', '200');
  ossFormData.append('key', ossToken.dir); // 上传地址+ 名称    从token接口返回
  ossFormData.append('policy', ossToken.policy);
  ossFormData.append('OSSAccessKeyId', ossToken.accessid);
  ossFormData.append('callback', ossToken.callback);
  ossFormData.append('signature', ossToken.signature);
  ossFormData.append('file', file);
  return axios.post(ossToken.host, ossFormData, {
    headers:{
      'Content-Type': 'multipart/form-data'
    }
  });
}
/**
 * 作品列表
 * @returns 
 */
export const designList = (params: any) => {
  return request.get(`${getApiHost()}/design/list`, params);
}
/**
 * 保存作品
 * 
 */
export const saveDesign = (params:any)=>{
  return request.post(`${getApiHost()}/design/save`, params);
}

/**
 * SEO保存作品
 * 
 */
export const initSaveDesign = (params:any)=>{
  return request.post(`${getApiHost()}/seo/initSave`, params);
}


/**
 * 获取作品详情
 * 
 */
export const getDesignDetail = (params:any)=>{
  return request.get(`${getApiHost()}/design/detail`, params);
}
/**
 * 获取作品详情
 * 
 */
export const getShareDesignDetail = (params:any)=>{
  return request.get(`${getApiHost()}/design/share/detail`, params);
}
/**
 * 兑换会员
 * 
 */
export const codeRedeem = (params:any)=>{
  return request.get(`${getApiHost()}/code/redeem`, params);
}
/**
 * 删除作品
 * 
 */
export const removeDesign = (params:any)=>{
  return request.post(`${getApiHost()}/design/remove`, params);
}
/**
 * 解析DOC
 * 
 */
export const parseDoc = (params:any)=>{
  return request.get(`${getApiHost()}/pares/file`, params);
}
export const getChannelList = ()=>{
  return request.get(`${getApiHost()}/user/channel/list`);
}
export const downloadRecord = (params:any)=>{
  return request.get(`${getApiHost()}/design/download/record`, params);
} 

